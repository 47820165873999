.entry-wapper {
    margin: 20px 0;
    padding: 0 20px;
}
.entry {
    background: @white;
    border-radius: 5px;
    transition: all 0.2s linear 0s;
    &:hover {
        box-shadow: 0 10px 30px rgba(0, 0, 0, 0.05);
    }
    .entry-pic {
        float: left;
        width: 320px;
        box-shadow: 5px 10px 20px rgba(0, 0, 0, 0.05);
        border-radius: 5px 0 0 5px;
        img {
            background: #e2e5e8;
            border-radius: 5px 0 0 5px;
            display: block;
        }
    }
    .entry-info {
        float: left;
        width: 650px;
        padding: 0 30px;
        .title {
            font-size: 20px;
            margin: 25px 0 8px;
        }
        .datetime {
            font-size: 16px;
            color: @green;
            margin: 10px 0;
        }
        .desc {
            font-size: 16px;
            color: #999;
            line-height: 26px;
            margin: 8px 0;
            .text-hide-row(3);
        }
    }
    .entry-view {
        float: right;
        width: 150px;
        text-align: center;
        padding: 65px 0;
        a {
            display: block;
        }
        .af-icon {
            font-size: 3em;
            color: #ddd;
        }
        span {
            font-size: 14px;
            color: #999;
            display: block;
            clear: both;
        }
        .event-status {
            height: 37px;
            margin: 16px 0;
            .ing {
                color: @white;
                padding: 9px 15px;
                background: @green;
                border-radius: @border-radius-circle;
                display: inline;
            }
            .closed {
                color: @white;
                margin-right: 20px;
                padding: 9px 15px;
                background: #bbb;
                border-radius: @border-radius-circle;
                display: inline;
            }
            .@{ns}icon {
                font-size: 1.8em;
                color: #ddd;
                vertical-align: -6px;
            }
        }
    }
    &.end {
        &:hover {
            box-shadow: none;
        }
        .entry-info {
            a {
                color: #999;
            }
            .datetime {
                color: #999;
            }
        }
    }
}
