//
// Tab
// @desc:   下拉菜单，tab.js 配套样式
// ---------------------------------------------------------------------------
.@{ns}nav-tabs{
    & > li{
        min-width:40px;padding:0 10px; border-bottom:2px solid transparent; line-height:40px; text-align:center; cursor:pointer;
        &.active{ border-bottom-color:@green;}
    }
}
.@{ns}tab-content{
    display:none; border:1px solid transparent; min-height:100px;
    &.active{ display:block;}
}
.@{ns}tabs-loading-tip{
    padding:30px 0; text-align:center;
    .reload{
        &>p{ margin-bottom:.8em;}
    }
}

