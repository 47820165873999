//
// Dropdown
// @desc:   下拉菜单，dropdown.js 配套样式
// ---------------------------------------------------------------------------
.@{ns}dropdown{
	position:relative; display:inline-block;

	.dropdown-content{
	    display:none; position:absolute; top:100%; right:0; min-width:@dropdown-min-width; margin-top:-1px; background:@dropdown-content-bg; box-shadow:@shardow-normal; border-radius:@dropdown-content-radius; border:1px solid @dropdown-border-color; z-index:@dropdown-index;
	    & > li{
			padding:@dropdown-content-padding; border-bottom:1px solid @dropdown-li-border-color;
            &:hover{ background:@dropdown-hover;}
            &:first-of-type{ border-radius:@dropdown-content-radius @dropdown-content-radius 0 0;}
			&:last-of-type{ border-bottom:none; border-radius:0 0 @dropdown-content-radius @dropdown-content-radius;}
		}
		a{
            color:#666; display:block; white-space:nowrap; line-height:@default-line-height;
            &:hover{ color:@grey-dark;}
        }
        &.align-right{ left:auto; right:0;}
	}
}
