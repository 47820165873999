.history {
    position: relative;
    display: block;
    padding: 50px 0 70px 0;
    overflow: hidden;
    .history-carousel {
        position:relative;
    }
    .carousel-outer {
        position: relative;
        &:before {
            position: absolute;
            content: '';
            left: -1000px;
            right: -10000px;
            top: 140px;
            height: 1px;
            background-color: #e3e3e3;
        }
        .thumbs-outer {
            position: relative;
            overflow: hidden;
        }
        .content-carousel {
            max-width: 950px;
            margin: 0 auto;
            .owl-item {
                &.active {
                    .content-inner {
                        position: relative;
                    }
                }
            }
            .owl-stage-outer {
                padding-top: 0;
            }
            .owl-nav {
                position: absolute;
                left: 0;
                bottom: 50%;
                width: 100%;
                .owl-prev {
                    position: absolute;
                    top: -21px;
                    left: -62px;
                    width: 42px;
                    height: 42px;
                    color: #d1d1d1;
                    line-height: 44px;
                    text-align: center;
                    border-radius: 50%;
                    font-weight: 700;
                    background-color: transparent;
                    transition: all 0.3s ease;
                    font-size: 20px;
                    margin: 0;
                    padding: 0;
                }
                .owl-next {
                    position: absolute;
                    top: -21px;
                    right: -62px;
                    width: 42px;
                    height: 42px;
                    color: #d1d1d1;
                    line-height: 44px;
                    text-align: center;
                    border-radius: 50%;
                    font-weight: 700;
                    background-color: transparent;
                    transition: all 0.3s ease;
                    font-size: 20px;
                    margin: 0;
                    padding: 0;
                }
            }
        }
        .content-slide {
            position: relative;
            .content-inner {
                position: relative;
                text-align: center;
                max-width: 950px;
                min-height: 151px;
                margin: 0 auto;
                padding: 33px 100px 33px;
                background-color: @green;
                .date {
                    color: #fff;
                    font-size: 15px;
                    font-weight: 500;
                    text-transform: uppercase;
                }
                h3 {
                    color: #fff;
                    font-size: 20px;
                    display: block;
                    margin: 8px 0 0;
                }
            }
        }
    }
    .history-carousel {
        .thumbs-carousel {
            position: relative;
            max-width: 360px;
            margin: 0 auto;
            margin-bottom: 30px;
            text-align: center;
            &:before {
                position: absolute;
                bottom: 10px;
                left: 0;
                width: 1px;
                height: 50px;
                background: #e3e3e3;
                content: "";
                z-index: 3;
            }
            &:after {
                position: absolute;
                bottom: 10px;
                right: 0;
                width: 1px;
                height: 50px;
                background: #e3e3e3;
                content: "";
                z-index: 3;
            }
            .owl-stage-outer {
                overflow: visible;
            }
            li {
                position: relative;
                color: #e9e9e9;
                font-size: 64px;
                font-weight: 500;
                cursor: pointer;
                line-height: 1em;
                text-align: center;
                padding: 0;
                transition: all 0.3s ease;
                display: inline-block;
                &:before {
                    position: absolute;
                    bottom: -84px;
                    left: 10px;
                    width: 16px;
                    height: 16px;
                    border-radius: 50%;
                    border: 2px solid #e3e3e3;
                    background: #f5f5f5;
                    content: "";
                    z-index: 3;
                }
                &:after {
                    position: absolute;
                    bottom: -84px;
                    right: 10px;
                    width: 16px;
                    height: 16px;
                    border-radius: 50%;
                    border: 2px solid #e3e3e3;
                    background: #f5f5f5;
                    content: "";
                    z-index: 3;
                }
            }
            .owl-item {
                &.active {
                    position: relative;
                    &:before {
                        position: absolute;
                        bottom: -84px;
                        left: 50%;
                        width: 16px;
                        height: 16px;
                        border-radius: 50%;
                        border: 2px solid @green;
                        background: @green;
                        content: "";
                        z-index: 3;
                        margin-left: -8px;
                    }
                    li {
                        position: relative;
                        color: @green;
                    }
                }
            }
            .owl-nav {
                position: relative;
                height: 70px;
                margin: 42px 0 0;
                z-index: 999;
                .owl-prev {
                    position: absolute;
                    left: -385px;
                    top: 9px;
                    font-size: 22px;
                    color: #d1d1d1;
                    font-weight: 700;
                    width: 70px;
                    height: 70px;
                    border-radius: 50%;
                    border: 4px solid #e9e9e9;
                    display: inline-block;
                    background-color: #f5f5f5;
                    transition: all 0.3s ease;
                    margin: 0;
                    padding: 20px 0;
                    z-index: 999;
                }
                .owl-next {
                    position: absolute;
                    right: -385px;
                    top: 9px;
                    font-size: 22px;
                    color: #d1d1d1;
                    font-weight: 700;
                    width: 70px;
                    height: 70px;
                    border-radius: 50%;
                    border: 4px solid #e9e9e9;
                    display: inline-block;
                    background-color: #f5f5f5;
                    transition: all 0.3s ease;
                    margin: 0;
                    padding: 20px 0;
                    z-index: 999;
                }
            }
        }
    }
}
.history .history-carousel .thumbs-carousel .owl-nav .owl-prev:hover,
.history .history-carousel .thumbs-carousel .owl-nav .owl-next:hover {
    color: #222;
    background: @green;
    border-color: @green;
}
.history .carousel-outer .content-carousel .owl-nav .owl-next:hover,
.history .carousel-outer .content-carousel .owl-nav .owl-prev:hover {
    color: #fff;
    background-color: @green;
}

.culture-wrapper {
    background: url("../images/culture.png") no-repeat center center;
}

.culture-box {
    width: 60%;
    margin: 20px 0;
    padding: 20px;
    background: #fff;
    border: 1px solid #eee;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.05);
    border-radius: 5px;
    h3 {
        font-size: 24px;
        margin-bottom: 10px;
    }
    h4 {
        font-size: 20px;
        color: @green;
        text-transform: uppercase;
        margin-bottom: 10px;
    }
    .desc {
        font-size: 16px;
        margin-bottom: 20px;
    }
    .border3 {
        width: 95px;
        height: 1px;
        background: #ddd;
    }
}
