figure {
    margin: 0;
    overflow: hidden;
}
/* game figure */
.game-figure {
    position: relative;
    border-radius: 5px 5px 0 0;
}
.game-figure::before {
    position: absolute;
    top: 0;
    left: -75%;
    z-index: 2;
    display: block;
    content: '';
    width: 50%;
    height: 100%;
    background: -webkit-linear-gradient(left, rgba(255,255,255,0) 0%, rgba(255,255,255,.3) 100%);
    background: linear-gradient(to right, rgba(255,255,255,0) 0%, rgba(255,255,255,.3) 100%);
    -webkit-transform: skewX(-25deg);
    transform: skewX(-25deg);
    visibility: hidden;
}
.game-item:hover .game-figure::before {
    -webkit-animation: shine .75s;
    animation: shine .75s;
    visibility: visible;
}
@-webkit-keyframes shine {
    100% {
        left: 125%;
    }
}
@keyframes shine {
    100% {
        left: 125%;
    }
}
