.contact {
    padding: 40px 0;
    .business-contacts {
        .contact-item-wrapper {
            padding: 10px;
            .contact-item {
                padding: 20px;
                background: @white;
                border: 1px solid #eee;
                border-top: 3px solid @green;
                border-radius: 5px;
                box-shadow: 0 2px 10px rgba(0, 0, 0, 0.02);
                transition: all 0.2s linear 0s;
                .title {
                    font-weight: 500;
                    font-size: 18px;
                    color: #666;
                    margin-bottom: 80px;
                }
                .contact-avatar {
                    margin: 15px;
                    img {
                        width: 120px;
                        height: 120px;
                        background: @white;
                        border: 4px solid @white;
                        border-radius: @border-radius-circle;
                    }
                }
                .contact-info {
                    .nickname {
                        font-weight: 400;
                        font-size: 18px;
                        margin-bottom: 10px;
                    }
                    p {
                        font-size: 16px;
                        line-height: 28px;
                        .af-icon {
                            color: #bbb;
                            margin-right: 5px;
                        }
                    }
                }
                &:hover {
                    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.05);
                    transform: translateY(-5px);
                }
            }
        }
    }
}
.company {
    padding: 50px 0;
    background: url("../images/address-bg.png") no-repeat 76% bottom;
    .location {
        font-size: 16px;
        color: #777;
        margin-left: 20px;
        padding: 20px 0;
        .title {
            font-weight: 400;
            font-size: 18px;
            color: @grey-dark;
            margin-bottom: 5px;
        }
        p {
            line-height: 1.5;
        }
    }
}
/* baidu map overwrite */
#allmap {
    width: 100%;
    height: 360px;
    border-radius: 5px;
    img {
        max-width: none!important;
    }
}
.BMapLib_SearchInfoWindow {
    font-family: "Segoe UI", Helvetica, Tahoma, "PingFang SC", "Microsoft YaHei", FreeSans, "Droid Sans", "wenquanyi micro hei", "Hiragino Sans GB", "Hiragino Sans GB W3", Arial, sans-serif!important;
    .BMapLib_bubble_title {
        padding: 0 8px!important;
    }
    .BMapLib_bubble_content {
        padding: 5px!important;
    }
}
