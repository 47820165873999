.home-slider {
    max-height: 768px;
    overflow: hidden;
}
.home-about {
    padding: 50px 0;
    .about-video-holder {
        position: relative;
        display: block;
        .video-holder {
            position: relative;
            display: block;
            overflow: hidden;
            max-width: 470px;
            width: 100%;
            border-radius: 5px;
            img {
                transform: scale(1);
                transition: all 0.5s ease 0s;
                width: 100%;
            }
            .overlay-gallery {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-color: transparent;
                transition: all 500ms ease;
                .icon-holder {
                    display: table;
                    height: 100%;
                    width: 100%;
                    text-align: center;
                    .icon {
                        display: table-cell;
                        vertical-align: middle;
                        text-align: center;
                        .inner {
                            width: 75px;
                            height: 75px;
                            display: inline-block;
                            background: rgba(255, 255, 255, 0.97);
                            border-radius: 50%;
                            transition: all 0.5s ease-in-out 0s;
                            a {
                                width: 75px;
                                height: 75px;
                                display: block;
                                border-radius: 50%;
                                span:before {
                                    color: @green;
                                    font-size: 30px;
                                    display: block;
                                    padding: 22px 0 23px 5px;
                                }
                            }
                        }
                    }
                }
            }
            &:hover {
                .overlay-gallery {
                    background-color: rgba(0, 0, 0, 0.40);
                    .icon-holder {
                        .icon {
                            .inner {
                                background: rgba(255, 255, 255, 0.90);
                            }
                        }
                    }
                }
                img {
                    transform: scale(1.1);
                }
            }
        }
    }
    .about-text-holder {
        padding-left: 10px;
        position: relative;
        display: block;
        .sec-title {
            position: relative;
            text-align: left;
            margin-top: -3px;
            margin-bottom: 0;
            padding-bottom: 15px;
        }
        .inner-content {
            display: block;
            h3 {
                color: #222222;
                font-size: 24px;
                line-height: 32px;
                margin: 0 0 18px;
            }
            p {
                font-size: 15px;
                color: @grey;
                line-height: 1.8;
                margin: 0 0 18px;
            }
            a {
                padding: 12px 35px;
                border-radius: 999em;
            }
        }
    }
    .about-pic-holder {
        img {
            border-radius: 5px;
        }
    }
}
.company-values {
    position: relative;
    display: block;
    width: 100%;
    text-align: center;
    margin-top: 50px;
    border: 2px solid #f2f2f2;
    border-right: none;
    overflow: hidden;
    .single-box {
        position: relative;
        display: inline-block;
        float: left;
        width: 25%;
        border-right: 2px solid #f2f2f2;
        background: #ffffff;
        padding: 15px 0;
        .icon-holder {
            display: block;
            padding-bottom: 14px;
            span:before {
                color: #c9c9c9;
                font-size: 48px;
                line-height: 64px;
            }
        }
        .title-holder {
            display: block;
            h3 {
                color: #555;
                font-size: 15px;
                line-height: 26px;
            }
        }
        .overlay-content {
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            background: @green;
            opacity: 0;
            visibility: hidden;
            transition: .5s ease;
            transform: translate(0,-50px) scale(1);
            .box {
                display: table;
                height: 100%;
                width: 100%;
                .inner-content {
                    display: table-cell;
                    text-align: center;
                    vertical-align: middle;
                    padding: 0 20px;
                    h3 {
                        color: #fff;
                        font-size: 18px;
                        line-height: 26px;
                        margin: 0 0 18px;
                    }
                    p {
                        color: #fff;
                        margin: 0;
                    }
                }
            }
        }
        &.alt {
            background: #f9f9f9;
        }
        &:hover {
            .overlay-content {
                opacity: 1;
                visibility: visible;
                transform: translate(0,0px) scale(1);
            }
        }
    }
}
.home-news {
    padding: 40px 0 50px 0;
    background: #f5f5f5;
    .container {
        padding: 0 5px;
    }
    .title-border {
        font-size: 0;
        width: 50px;
        height: 3px;
        text-indent: -9999px;
        margin: 0 auto 20px auto;
        background: @green;
    }
    .news-item-wrapper {
        margin: 15px 0;
        padding: 0 20px;
    }
    .news-item {
        background: @white;
        border-radius: 5px;
        box-shadow: 0 5px 10px rgba(0, 0, 0, 0.05);
        transition: all 0.2s linear 0s;
        &:hover {
            box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
            transform: translateY(-5px);
        }
        .news-item-pic {
            width: 100%;
            position: relative;
            top: 0;
            left: 0;
            box-shadow: 5px 10px 20px rgba(0, 0, 0, 0.05);
            border-radius: 5px 5px 0 0;
            img {
                background: #e2e5e8;
                border-radius: 5px 5px 0 0;
                display: block;
            }
        }
        .news-item-info {
            padding: 20px;
            h3.title {
                float: left;
                margin-bottom: 10px;
                a {
                    color: @black;
                    transition: all 0.2s linear 0s;
                    &:hover {
                        color: @green;
                    }
                }
            }
            .date {
                font-size: 14px;
                font-weight: 500;
                color: #999;
                position: relative;
                display: block;
                padding-left: 40px;
                line-height: 30px;
                z-index: 1;
                margin: 0 0 2px;
                &:before {
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    left: 0;
                    height: 1px;
                    width: 30px;
                    background: #ddd;
                    z-index: -1;
                    content: "";
                    margin: 14px 0;
                }
            }
            .desc {
                font-size: 15px;
                color: #999;
                padding-right: 25px;
                .text-hide-row(3);
            }
        }
    }
}
.home-games {
    padding: 40px 0 50px 0;
    .container {
        padding: 0 5px;
    }
    .title-border {
        font-size: 0;
        width: 50px;
        height: 3px;
        text-indent: -9999px;
        margin: 0 auto 20px auto;
        background: @green;
    }
    .game-item-wrapper {
        margin: 15px 0;
        padding: 0 20px;
    }
    .game-item {
        background: @white;
        border-radius: 5px;
        border: 2px solid #f2f2f2;
        box-shadow: 0 5px 10px rgba(0, 0, 0, 0.02);
        transition: all 0.2s linear 0s;
        &:hover {
            border: 2px solid #eee;
            box-shadow: 0 10px 30px rgba(0, 0, 0, 0.05);
            transform: translateY(-5px);
        }
        .game-item-pic {
            box-shadow: 5px 10px 20px rgba(0, 0, 0, 0.05);
            border-radius: 5px 5px 0 0;
            a {
                display: block;
            }
            img {
                background: #e2e5e8;
                border-radius: 5px 5px 0 0;
                display: block;
            }
        }
        .game-item-info {
            padding: 20px 20px 20px 90px;
            position: relative;
            h3.title {
                font-size: 20px;
                float: left;
                margin-bottom: 5px;
            }
            .game-icon {
                position: absolute;
                left: 20px;
                top: 20px;
            }
            .game-type {
                float: left;
                margin-left: 10px;
                padding: 4px 0;
                span {
                    font-size: 12px;
                    color: @white;
                    background: lighten(@green,15%);
                    padding: 2px 6px;
                    border-radius: @border-radius-base;
                }
            }
            .desc {
                color: #999;
                padding-right: 25px;
                .text-hide-ellipsis();
            }
        }
        .game-item-links {
            position: relative;
            text-align: center;
            background: #fdfdfd;
            border-top: 1px solid #eee;
            border-radius: 0 0 5px 5px;
            .clearfix();
            a {
                color: #999;
                padding: 10px 0;
                .@{ns}icon {
                    color: #ddd;
                }
                &:hover {
                    color: #666;
                    .@{ns}icon {
                        color: #bbb;
                    }
                }
                &:first-child {
                    border-right: 1px solid #eee;
                }
            }
            .download-pop {
                position: absolute;
                top: -185px;
                left: 10px;
                padding: 20px 20px 15px 20px;
                background: @white;
                border-radius: 10px;
                border: 1px solid #ccc;
                box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
                clear: both;
                display: none;
                .arrow {
                    position: absolute;
                    bottom: -22px;
                    left: 50%;
                    margin-left: -11px;
                    display: block;
                    .arr-down(11px, #fff);
                }
                .qrcode-tips {
                    color: #999;
                    margin-top: 5px;
                }
            }
        }
    }
}
.home-more {
    text-align: center;
    margin-top: 20px;
    .@{ns}icon {
        color: #ddd;
    }
}
.home-data {
    padding: 40px 0;
    ul {
        position: relative;
        display: block;
        overflow: hidden;
        border-top: 1px solid #eee;
        border-bottom: 1px solid #eee;
    }
    .data-item {
        position: relative;
        display: inline-block;
        float: left;
        max-width: 280px;
        width: 100%;
        text-align: center;
        padding: 49px 50px 42px;
        border-right: 1px solid #eee;
        &:last-child{
            border-right: none;
        }
        .icon{
            position: relative;
            display: block;
            margin-bottom: 28px;
            span:before{
                color: #c9c9c9;
                font-size: 80px;
                line-height: 80px;
                transition: all 500ms ease;
            }
        }
        &:hover {
            .icon span:before{
                color: @green;
            }
        }
        h1 {
            span {
                position: relative;
                display: inline-block;
                color: #222;
                font-size: 36px;
                font-weight: 700;
            }
        }
        .title h3 {
            color: #222222;
            font-size: 18px;
            margin: 3px 0 14px;
            text-transform: capitalize;
        }
        .text p {
            color: #848484;
            margin: 0;
        }
    }
}
.home-platforms {
    padding: 40px 0;
    background: #f5f5f5;
    .container {
        padding: 0 5px;
    }
    .title-border {
        font-size: 0;
        width: 50px;
        height: 3px;
        text-indent: -9999px;
        margin: 0 auto 20px auto;
        background: @green;
    }
    .platform-item-wrapper {
        margin: 15px 0;
        padding: 0 20px;
    }
    .platform-item {
        background: @white;
        border-radius: 5px;
        box-shadow: 0 5px 10px rgba(0, 0, 0, 0.05);
        transition: all 0.2s linear 0s;
        &:hover {
            box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
            transform: translateY(-5px);
        }
        .platform-item-pic {
            border-radius: 5px;
            img {
                border-radius: 5px;
            }
        }
    }
}
.home-featured {
    padding: 40px 0;
    background: #f5f5f5;
    h2.title {
        font-weight: 600;
        font-size: 24px;
        text-align: center;
        margin-bottom: 10px;
    }
    .title-border {
        font-size: 0;
        width: 50px;
        height: 4px;
        text-indent: -9999px;
        margin: 0 auto 20px auto;
        background: @green;
        border-radius: 2px;
    }
    .featured-item-wrapper {
        margin: 15px 0;
    }
    .featured-item {
        background: @white;
        border-radius: 10px;
        box-shadow: 0 2px 10px rgba(0, 0, 0, 0.03);
        transition: all 0.2s linear 0s;
        &:hover {
            box-shadow: 0 10px 30px rgba(0, 0, 0, 0.05);
            transform: translateY(-5px);
        }
        .featured-item-pic {
            border-radius: 10px;
            img {
                background: #e2e5e8;
                border-radius: 10px;
                display: block;
            }
        }
    }
}
