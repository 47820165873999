//
// Default Style
// ---------------------------------------------------------------------------

@ns:                    ~"af-";

body {
    background: @white;
}
.container {
    max-width: 1160px;
    margin: 0 auto;
    padding: 0 15px;
}
.header {
    color: @black;
    width: 100%;
    box-shadow: 0 2px 20px rgba(0, 0, 0, 0.1);
    .topbar {
        height: 85px;
        line-height: 40px;
        background: @white;
        .title {
            float: left;
            margin: 20px 0;
            a {
                width: 185px;
                height: 45px;
                display: block;
                position: relative;
                z-index: 1;
                &:after {
                    content: '';
                    z-index: -1;
                    position: absolute;
                    left: auto;
                    right: 20px;
                    height: 1000%;
                    bottom: -20px;
                    width: 800%;
                    display: block;
                    background: @green;
                    transform: skew(20deg);
                }
            }
        }
        .site-navigation {
            float: left;
            font-size: 15px;
            font-weight: 600;
            max-width: 650px;
            margin-left: 120px;
            .nav-menu {
                .menu-item {
                    float: left;
                    display: inline-block;
                    height: 85px;
                    line-height: 85px;
                    padding: 0 25px;
                    position: relative;
                    transition: all 0.2s linear 0s;
                    .menu-link {
                        color: @grey-dark;
                        transition: all 0.3s linear 0s;
                        position: relative;
                        &:before {
                            position: absolute;
                            bottom: -10px;
                            left: 0;
                            right: 0;
                            width: 8px;
                            height: 4px;
                            background: @green;
                            content: "";
                            margin: 0 auto;
                            transform: rotate(-45deg);
                            transition: all .2s ease-in-out;
                            opacity: 0;
                        }
                        &:after {
                            position: absolute;
                            bottom: -10px;
                            left: 0;
                            right: 0;
                            width: 8px;
                            height: 4px;
                            background: lighten(@green,10%);
                            content: "";
                            margin: 0 auto;
                            transform: rotate(-45deg);
                            transition: all .4s ease-in-out;
                            opacity: 0;
                        }
                    }
                    &:hover {
                        .menu-link {
                            color: @green;
                            &:before {
                                opacity: 1;
                                left: -8px;
                            }
                            &:after {
                                opacity: 1;
                                right: -8px;
                            }
                        }
                    }
                    &.current-menu-item {
                        .menu-link {
                            color: @green;
                            &:before {
                                opacity: 1;
                                left: -8px;
                            }
                            &:after {
                                opacity: 1;
                                right: -8px;
                            }
                        }
                    }
                }
                > li > ul {
                    position: absolute;
                    left: 0;
                    top: 120%;
                    width: 160px;
                    background: transparent;
                    padding: 10px 0 0;
                    z-index: 100;
                    visibility: hidden;
                    opacity: 0;
                    transition: all 500ms ease;
                    display: block;
                    border-bottom: 3px solid @green;
                    border-radius: 5px;
                    &:before {
                        position: absolute;
                        top: 10px;
                        left: 0;
                        width: 100%;
                        background: #fff;
                        content: "";
                        z-index: -1;
                        bottom: 0;
                        border-radius: 5px;
                        box-shadow: 0 2px 20px rgba(0, 0, 0, 0.1);
                    }
                    > li {
                        position: relative;
                        display: block;
                        float: none;
                        margin: 0;
                        padding: 0;
                        width: 100%;
                        transition: all 500ms ease;
                        z-index: 1;
                        &:before {
                            position: absolute;
                            left: 30px;
                            right: 30px;
                            height: 100%;
                            border-bottom: 1px solid #e9e9e9;
                            content: "";
                            z-index: -1;
                        }
                        &:last-child:before {
                            border-bottom: none;
                        }
                        a {
                            position: relative;
                            display: block;
                            color: #222;
                            font-size: 15px;
                            font-weight: 400;
                            line-height: 20px;
                            text-transform: capitalize;
                            transition: all 500ms ease 0s;
                            padding: 15px 25px;
                            &:before {
                                position: absolute;
                                top: 0;
                                left: 30px;
                                bottom: 0;
                                right: auto;
                                width: 7px;
                                height: 7px;
                                background: @green;
                                border-radius: 50%;
                                content: "";
                                border-radius: 50%;
                                margin: 23px 0;
                                transform: none;
                                opacity: 1;
                                transform: scale(0);
                                transition: all 500ms ease;
                            }
                        }
                    }
                }
                > li:hover > ul {
                    top: 100%;
                    opacity: 1;
                    visibility: visible;
                }
                li ul li:hover a:before{
                    transform: scale(1);
                    left: 30px;
                }
                li ul li a:after{
                    display: none;
                }
                li > ul > li:hover > a {
                    color: @green;
                    padding-left: 45px;
                }

            }
        }
        .snda {
            float: right;
            max-width: 200px;
            position: relative;
            z-index: 1;
            line-height: 1;
            padding: 16px;
            &:before {
                content: '';
                z-index: -1;
                bottom: -1px;
                position: absolute;
                left: -160px;
                right: auto;
                height: 1000%;
                width: 2000%;
                background: #f5f5f5;
                display: block;
                transform: skew(20deg);
                line-height: 1;
            }
        }
    }
}

.article {
    font-size: 18px;
    line-height: 1.8;
    background: #f5f5f5;
    .article-wrapper {
        margin: 40px 0;
        padding: 20px 50px;
        background: @white;
        border-radius: 5px;
    }
    h1 {
        font-size: 24px;
        text-align: center;
    }
    h2, h3 {
        font-size: 20px;
        color: @green;
        margin: 30px 0;
    }
    h4 {
        font-weight: 400;
        margin: 30px 0;
    }
    p {
        margin: 30px 0;
        text-indent: 0;
        text-align: justify;
        &.indent {
            text-indent: 2em;
        }
        &.center {
            text-indent: 0;
            text-align: center;
        }
        &.article-meta {
            font-size: 14px;
            text-align: center;
            margin-top: 0;
            padding-bottom: 30px;
            border-bottom: 1px solid #eee;
            span {
                color: @grey-light;
                margin: 0 10px;
            }
        }
        img {
            width: 60%;
        }
        a {
            color: @red;
            text-decoration: underline;
            &:hover {
                text-decoration: none;
            }
        }
        em {
            color: #999;
        }
        strong {
            &.red {
                color: @red;
            }
        }
    }
    blockquote {
        font-size: 16px;
        color: #666;
        line-height: 2;
        text-align: justify;
        margin: 30px 0;
        padding: 0 2em;
    }
    ul, ol {
        margin: 30px 0;
        padding-left: 20px;
        li {
            padding-left: 5px;
        }
    }
    ul {
        list-style: circle;
    }
    ol {
        list-style: decimal;
    }
}
.footer {
    color: #aaa;
    padding: 30px 0;
    background: #222;
    overflow: hidden;
    .footer-logo {
        margin-bottom: 20px;
    }
    .footer-right {
        p {
            line-height: 26px;
        }
        .footer-links {
            color: #666;
            margin-bottom: 10px;
            a {
                color: #eee;
                &:hover {
                    color: @white;
                }
            }
            em {
                margin: 0 20px;
            }
        }
        .copyright {
            a {
                color: #aaa;
                &:hover {
                    color: @white;
                }
            }
        }
        .cert {
            margin-top: 20px;
            span {
                margin: 0 30px 0 0;
            }
        }
    }
    .weixin-qrcode {
        float: right;
        text-align: center;
        margin: 25px 0 0;
        img {
            padding: 5px;
            background: #fff;
            border-radius: 5px;
        }
        p {
            margin-top: 8px;
        }
    }
}

.page-banner {
    position: relative;
    &::before {
        background: rgba(0, 0, 0, 0.6) none repeat scroll 0 0;
        content: "";
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
    }
}

.sub-menu {
    text-align: center;
    position: relative;
    top: -25px;
    li {
        box-shadow: 0 2px 20px rgba(0, 0, 0, 0.1);
        a {
            font-size: 15px;
            display: block;
            height: 50px;
            line-height: 50px;
            padding: 0 35px;
            background: #fff;
            &:hover {

            }
        }
        &.current-menu-item {
            a {
                color: #fff;
                background: @green;
            }
        }
    }
}

.sec-title {
    text-align: center;
    margin-bottom: 20px;
    .title {
        font-weight: 600;
        font-size: 24px;
        text-align: center;
        margin-bottom: 5px;
    }
    .inner {
        position: relative;
        display: inline-block;
        line-height: 14px;
        z-index: 1;
        .border1 {
            width: 50px;
            height: 2px;
            background: @green;
            display: inline-block;
            float: left;
            margin: 6px 0;
        }
        .border2 {
            width: 50px;
            height: 2px;
            background: @green;
            display: inline-block;
            float: right;
            margin: 6px 0;
        }
        span {
            font-family: sans-serif;
            position: relative;
            display: inline-block;
            color: @green;
            font-size: 16px;
            font-weight: 600;
            text-transform: capitalize;
            line-height: 14px;
            padding: 0 15px;
        }
    }
}

.page-title {
    line-height: 30px;
    margin: 0 0 40px;
    padding-left: 15px;
    border-left: 3px solid @green;
    h2 {
        font-size: 24px!important;
    }
    span {
        font-size: 20px!important;
        font-weight: 500;
        color: #999;
        margin-left: 15px;
    }
}

.pages {
    margin: 30px 0;
    text-align: center;
    a {
        margin: 0 15px;
    }
}

.page-about {
    .home-about .about-text-holder {
        padding: 0 10px 0 0;
    }
}

.page-news {
    .home-news .news-item-wrapper {
        margin: 20px 0;
    }
}
.page-games {
    .home-games {
        background: #f5f5f5;
        .game-item-wrapper {
            margin: 20px 0;
            .game-item {
                box-shadow: 0 5px 10px rgba(0, 0, 0, 0.05);
                border: 0;
                &:hover {
                    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
                }
            }
        }
    }
}
